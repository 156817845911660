<script>
import { useI18n } from '@/modules/i18n';

export default {
  name: 'V3PersonRelativeForm',
  mixins: [useI18n],

  props: {
    person: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      innerPerson: {},
      errors: {},
      halt: false,
    };
  },

  watch: {
    person: {
      immediate: true,
      handler() {
        this.innerPerson = { ...this.person };
        if (!this.innerPerson.gender) {
          this.innerPerson.gender = '1';
        }
      },
    },

    innerPerson: {
      deep: true,
      handler() {
        if (this.halt) {
          this.halt = false;
          return;
        }

        this.halt = true;
        this.$emit('update:person', { ...this.innerPerson });
      },
    },
  },

  computed: {
    availableIdTypes() {
      return Array.isArray(window?.PersonDocumentTypes)
        ? window?.PersonDocumentTypes
        : [
            { value: '1', text: 'T.I.' },
            { value: '2', text: 'C.C.' },
            { value: '3', text: 'C.E.' },
            { value: '4', text: 'R.C.' },
            { value: '5', text: 'NIT.' },
            { value: '6', text: 'PP.' },
            { value: '7', text: 'NUIP.' },
            { value: '9', text: 'T.E' },
            { value: '10', text: 'D.I.E' },
            { value: '11', text: 'NIT.E' },
            { value: '12', text: 'PEP' },
            { value: '8', text: 'OTRO' },
          ];
    },
  },

  i18n: {
    en: {
      'V3PersonRelativeForm.firstName': 'First Name',
      'V3PersonRelativeForm.lastName': 'Last Name',
      // 'V3PersonRelativeForm.lastName2': 'Second Last Name',
      'V3PersonRelativeForm.document': 'Document',
      'V3PersonRelativeForm.gender': 'Gender',
      'V3PersonRelativeForm.gender.male': 'Male',
      'V3PersonRelativeForm.gender.female': 'Female',
    },
    es: {
      'V3PersonRelativeForm.firstName': 'Nombre',
      'V3PersonRelativeForm.lastName': 'Apellidos',
      // 'V3PersonRelativeForm.lastName2': 'Segundo apellido',
      'V3PersonRelativeForm.document': 'Documento',
      'V3PersonRelativeForm.gender': 'Sexo',
      'V3PersonRelativeForm.gender.male': 'Masculino',
      'V3PersonRelativeForm.gender.female': 'Femenino',
    },
    de: {
      'V3PersonRelativeForm.firstName': 'Vorname',
      'V3PersonRelativeForm.lastName': 'Nachname',
      // 'V3PersonRelativeForm.lastName2': 'Zweiter Nachname',
      'V3PersonRelativeForm.document': 'Document',
      'V3PersonRelativeForm.gender': 'Geschlecht',
      'V3PersonRelativeForm.gender.female': 'weiblich',
      'V3PersonRelativeForm.gender.male': 'männlich',
    },
  },
};
</script>

<template>
  <div class="V3PersonRelativeForm">
    <div class="V3PersonRelativeForm__field">
      <label
        for="newRelative-firstname"
        v-text="$t('V3PersonRelativeForm.firstName')"
      ></label>
      <input
        type="text"
        required
        v-model="innerPerson.firstname"
        @input="errors.firstname = null"
      />
      <p
        class="V3PersonRelativeForm__error"
        v-show="errors.firstname"
        v-text="errors.firstname"
      >
      </p>
    </div>

    <div class="V3PersonRelativeForm__field">
      <label
        for="newRelative-lastname"
        v-text="$t('V3PersonRelativeForm.lastName')"
      ></label>
      <input
        type="text"
        required
        v-model="innerPerson.lastname"
        @input="errors.lastname = null"
      />
      <p
        class="V3PersonRelativeForm__error"
        v-show="errors.lastname"
        v-text="errors.lastname"
      ></p>
    </div>

    <div class="V3PersonRelativeForm__field">
      <label
        for="newRelative-document"
        v-text="$t('V3PersonRelativeForm.document')"
      ></label>

      <div style="display:flex">
        <select
          style="width:auto"
          v-model="innerPerson.idtype"
        >
          <option
            v-for="idType in availableIdTypes"
            :key="idType.value"
            :value="idType.value"
            v-text="idType.text"
          />
        </select>
        <input
          type="text"
          v-model="innerPerson.document"
          @input="errors.document = null"
        />
      </div>
      <p
        class="V3PersonRelativeForm__error"
        v-show="errors.document"
        v-text="errors.document"
      ></p>
    </div>

    <div class="V3PersonRelativeForm__field">
      <label
        for="newRelative-gender"
        v-text="$t('V3PersonRelativeForm.gender')"
      ></label>
      <select v-model="innerPerson.gender">
        <option
          value="1"
          v-text="$t('V3PersonRelativeForm.gender.male')"
        ></option>
        <option
          value="0"
          v-text="$t('V3PersonRelativeForm.gender.female')"
        ></option>
      </select>
    </div>
  </div>

</template>

<style lang="scss">
.V3PersonRelativeForm {
  &__field {
    margin-bottom: 1em;

    label {
      display: block;
    }
  }
}
</style>